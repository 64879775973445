import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button, Form, Input } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset from '../../assets/tick.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/banner.jpg';
import api from '../../api';
import './Welcome.scss';
import { useDispatch, useSelector } from 'react-redux';
import backIcon from '../../assets/back.png'
import logo from '../../assets/logo.png'
import validatePhoneNumber from '../../utils/isCorrectNumberPhone'
import BanQuyen from '../../components/BanQuyen';

const arrayText = [
  '097484***4 đã rút 35.000.000 đ',
  '033658***8 đã rút 30.000.000 đ',
  '087455***3 đã rút 45.000.000 đ',
  '033745***1 đã rút 100.000.000 đ',
  '035693***5 đã được duyệt hồ sơ',
  '033736***5 đã rút 80.000.000 đ',
];

const arrayText2 = [
  { phone: '097484***4', amount: '+35.000.000 đ' },
  { phone: '033658***8', amount: '+30.000.000 đ' },
  { phone: '087455***3', amount: '+45.000.000 đ' },
  { phone: '033745***1', amount: '+100.000.000 đ' },
  { phone: '033736***5', amount: '+35.000.000 đ' },
  { phone: '097328***4', amount: '+40.000.000 đ' }
];

export default function Welcome() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isWelcome, setIsWelcome] = useState(true)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });

  const onFinish = async (values) => {
    try {
      const { data } = await api.post('/users/check-phone-number', {
        phone: values.phone,
      });
      setLoading(false)
  
      if (data.isExist === true) {
        history.push('/auth?phone=' + values.phone)
      } else {
        history.push('/auth/signup?phone=' + values.phone)
      }
    } catch (error) {
      alert('System error: ', error.message)
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false)
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {isWelcome ? (
        <motion.div
          initial={{ opacity: 0.3, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="container"
        >
          <Carousel
            slidesToShow={2}
            dots={false}
            autoplay
            autoplaySpeed={2000}
            className="carousel-container2"
          >
            {arrayText2.map((item) => {
              return (
                <div className="carousel-item">
                  <div className="sl-phone">{item.phone}</div>
                  <div className="sl-amount">{item.amount}</div>
                </div>
              );
            })}
          </Carousel>

          <Carousel
            effect="fade"
            autoplay
            autoplaySpeed={3000}
            className="carousel-container"
          >
            <div>
              <div className="carousel slider2">
                <div className="sl-text-container"></div>
              </div>
            </div>
            <div>
              <div className="carousel slider3">
                <div className="sl-text-container"></div>
              </div>
            </div>
            <div>
              <div className="carousel slider4">
                <div className="sl-text-container"></div>
              </div>
            </div>
          </Carousel>

          <div className="body-text_login">
            <div className="title-1">Khoản vay đầu tiên có sẵn</div>
            <div className="title-2">30 triệu - 1 tỷ đồng</div>
            <ul className="list-info">
              <li>
                <i class="fa-solid fa-circle-check"></i>
                <span>Chỉ mất 3 phút đăng ký</span>
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i>
                <span>Thông tin chi tiết - Lãi suất minh bạch</span>
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i>
                <span>Nhận kết quả hồ sơ nhanh chóng</span>
              </li>
            </ul>
          </div>

          <Button
            className="choose-btn"
            onClick={() => {
              setIsWelcome(false);
            }}
          >
            Bắt đầu vay
          </Button>
        </motion.div>
      ) : (
        <div>
          <div className="rg-header">
            <div
              className="anticon anticon-left"
              onClick={() => {
                setIsWelcome(true);
              }}
            >
              <img src={backIcon} alt="loading..." />
            </div>
            <div className="rg-title">Đăng ký</div>
          </div>

          <div className="img-logo">
            <img src={logo} alt="loading" />
          </div>

          <div className="box-form">
            <p>Nhập số điện thoại</p>

            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="phone"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          new Error("Bạn chưa nhập số điện thoại")
                        );
                      } else if (!validatePhoneNumber(value)) {
                        return Promise.reject(
                          new Error("Số điện thoại không hợp lệ")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  className="phone-input"
                  placeholder="Nhập số điện thoại tại đây"
                />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  className="choose-btn choose-btn-next"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => setLoading(true)}
                >
                  Tiếp tục
                  <i className="fa-solid fa-angle-right icon-next"></i>
                  <i className="fa-solid fa-angle-right icon-next"></i>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
      <BanQuyen />
    </>
  );
}
