import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { LeftOutlined, LoadingOutlined, CopyFilled } from '@ant-design/icons';
import { Typography, Empty, Divider, Modal, Table, Spin, message } from 'antd';
import * as actions from '../../redux/actions/auth';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import moment from 'moment';
import { ContractForm } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import './MyCard.scss'

export default function MyContract() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/users/profile');
      const result = await api.get('/contracts');
      const data2 = result.data
      setLoading(false);
      if (data2.data.length > 0) setContract(data2.data[0]);
      dispatch(actions.initialLogin(data.data));
    })();
  }, []);
  const [showDetail, setShowDetail] = useState(false);

  const copyOnclick = (text) => {
    navigator.clipboard.writeText(text)
    message.success("Copy thành công!")
  }

  return (
    <motion.div
      style={{
        padding: "10px 15px",
        backgroundColor: "rgb(245, 245, 245)",
        minHeight: "100vh",
      }}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 5,
          borderBottom: "1px solid #eee",
        }}
      >
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => history.replace("/")}
          style={{ padding: 0 }}
        >
          <LeftOutlined style={{ fontSize: 25, color: "#555" }} />
        </motion.div>
        <Typography.Text strong style={{ fontWeight: 700, fontSize: 20 }}>
          Thông tin tài khoản thanh toán
        </Typography.Text>
        <div></div>
      </div>
      <div
        className="bank-container"
        // style={{
        //   padding: 10,
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   flexDirection: 'column',
        // }}
      >
        {loading ? (
          <div
            style={{
              height: "80vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              background: "rgba(0,0,0,0.07)",
            }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
            <Typography.Text>Đang tải dữ liệu...</Typography.Text>
          </div>
        ) : (
          <>
            {!contract?._id ? (
              <>
                <Empty description="Bạn chưa có khoản vay nào" />
                <motion.div
                  whileTap={{ opacity: 0.4, scale: 0.97 }}
                  style={{
                    background: "#002dbf",
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    marginTop: 30,
                    padding: 5,
                  }}
                  onClick={() => history.push("/vay")}
                >
                  <Typography.Text
                    style={{ color: "#fff", fontWeight: 700, fontSize: 17 }}
                  >
                    Đăng ký ngay
                  </Typography.Text>
                </motion.div>
              </>
            ) : (
              <>
                <Divider>
                  <Typography.Text style={{ fontSize: 16 }}>
                    Tài khoản ngân hàng
                  </Typography.Text>
                </Divider>
                <div>
                  <div className="field-item">
                    <Typography.Text className="container-title">
                      <span>Tên ngân hàng :</span>
                      <CopyFilled
                        onClick={() =>
                          copyOnclick(
                            "Ngân hàng TMCP Kỹ thương Việt Nam (TECHCOMBANK)"
                          )
                        }
                      />
                    </Typography.Text>
                    <Typography.Text className="field-content">
                      {"Ngân hàng TMCP Kỹ thương Việt Nam (TECHCOMBANK)"}
                    </Typography.Text>
                  </div>
                  <div className="field-item">
                    <Typography.Text className="container-title">
                      <span>STK ngân hàng :</span>
                      <CopyFilled onClick={() => copyOnclick("37688688")} />
                    </Typography.Text>
                    <Typography.Text className="field-content">
                      {"37688688"}
                    </Typography.Text>
                  </div>
                  <div className="field-item">
                    <Typography.Text className="container-title">
                      <span>Tên thụ hưởng :</span>
                      <CopyFilled
                        onClick={() =>
                          copyOnclick("CONG TY TNHH MTV HOME VN")
                        }
                      />
                    </Typography.Text>
                    <Typography.Text className="field-content">
                      {"CONG TY TNHH MTV HOME VN"}
                    </Typography.Text>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </motion.div>
  );
}
const ModalDetailPayment = ({ visible, onCancel, times, amount }) => {
  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + (amount - (index * amount) / times) * 0.01,
    period: `${new Date().getDate()} - ${
      (new Date().getMonth() + 1 + index + 1) % 12 === 0
        ? 12
        : (new Date().getMonth() + 1 + index + 1) % 12
    }`,
  }));
  const columns = [
    {
      title: 'Kỳ',
      dataIndex: 'index',
      key: 'index',
      render: (text) => <Typography.Text>Kì thứ {text}</Typography.Text>,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (
        <Typography.Text strong>
          {Math.ceil(text).toLocaleString()}
        </Typography.Text>
      ),
    },
    {
      title: 'Ngày đóng',
      dataIndex: 'period',
      key: 'period',
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      closeIcon={() => <></>}
      title={'Chi tiết trả nợ'}
    >
      <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};
