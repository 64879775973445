import React from "react";
import { Image, Typography } from "antd";
import tickAsset from "../assets/tick.png";

export default function BanQuyen() {
  return (
    <div
      style={{
        paddingTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 100,
      }}
    >
      <Image src={tickAsset} preview={false} style={{ maxWidth: 100 }} />
      <Typography.Text
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#555",
          textAlign: "center",
        }}
      >
        &reg; Bản quyền thuộc về Công ty Tài chính Trách nhiệm hữu hạn HOME
        CREDIT
      </Typography.Text>
      
    </div>
  );
}
